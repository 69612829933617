import React from "react";

const Loading = () => {
  return (
    <div
      className="section section-center"
      style={{ display: "flex", height: "100vh" }}
    >
      <div className="loading"></div>
    </div>
  );
};

export default Loading;

import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { Navbar, Navbar2 } from "../common";
import images from "../constants/images";

const ErrorPage = () => {
  return (
    <>
      <Navbar2 color="#fff" logo={images.logo} />
      <Wrapper className="page-100">
        <section>
          <h1 style={{ color: "black" }}>404</h1>
          <h3
            style={{
              display: "block",
              textAlign: "center",
              width: "70%",
              // marginBottom: "2rem",
              margin: "2rem auto",
              minWidth: "300px",
            }}
          >
            (Page not found)
          </h3>
          {/* <Link to="/" className="product-button" style={{ marginTop: "2rem" }}>
            Go to Home
          </Link> */}
        </section>
      </Wrapper>
    </>
  );
};

const Wrapper = styled.main`
  background: #fff;
  /* background: -webkit-linear-gradient(to right, #ffefba, #ffffff); */
  /* background: linear-gradient(to right, #ffefba, #ffffff); */

  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 90vh;
  h1 {
    font-size: 10rem;
    font-weight: 700;
  }
  h3 {
    text-transform: none;
    margin-bottom: 2rem;
  }

  @media screen and (max-width: 720px) {
    h1 {
      font-size: 4rem;
    }
  }
`;

export default ErrorPage;
